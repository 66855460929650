@import "../common/vars";

.links {

    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 70px;

    @media screen and (max-width: 756px) {
    
        display: none;
    
    }
    


    &-button-prev, &-button-next {

        width: 35px;
        height: 35px;
        background-color: $color-green;
        position: absolute;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0px;
        cursor: pointer;
        background: $color-green url('/images/arrow_right.svg') no-repeat center;
        background-size: 40%;


    }


    &-button-prev {

        right: 60px;
        transform: rotateY(180deg);


    }

    &-button-next {

        right: 0px;

    }


    &__text {

        font-size: 14px;
        color: $color-text;
        margin-bottom: 12px;

    }

    &__link {

        font-size: 14px;
        color: $color-cyan;
        text-decoration: none;

    }

    &-container {

        width: 100%;
        overflow: hidden;
        margin-top: 20px;

    }

    &-wrapper {

        display: inline-flex;

    }

    &-slide {

        padding: 25px;
        border: 1px solid $color-cyan;
        border-radius: 5px;
        box-sizing: border-box;
        position: relative;
        transition: .6s ease;
        cursor: pointer;
        background: url("/images/image_main.jpg") no-repeat center;
        background-size: contain;

        &:before {

            content: '';
            width: 100%;
            height: 100%;
            background: url('/images/link_hover.png') no-repeat center;
            background-size: 120%;
            opacity: 0;
            position: absolute;
            left: 0;
            top: 0;
            transition: .6s ease;
            z-index: -1;


        }

        &:hover {

            .links__text,.links__link {

                color: #fff;
                transition: .6s ease;

            }

            &:before {

                transition: .6s ease;
                opacity: 1;

            }       

        }

    }
    
}


