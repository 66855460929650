@import "../common/vars";

.news {

    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 45px;

    &__row {
    
        display: flex;
        align-items: center;

    } 

    &__news-all {
    
        font-size: 13px;
        color: $color-gray;
        margin-left: 33px;
    
    }

    &-button-prev, &-button-next {

        width: 35px;
        height: 35px;
        background-color: $color-green;
        position: absolute;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 26px;
        cursor: pointer;
        background: $color-green url('/images/arrow_right.svg') no-repeat center;
        background-size: 40%;


    }

    &-button-prev {

        right: 60px;
        transform: rotateY(180deg);

    }

    &-button-next {

        right: 0px;

    }

}

.swiper-news{

    &__container {

        width: 100%;
        overflow: hidden;
        padding: 5px 0;

    }

    &__wrapper {
    
        display: inline-flex;
    
    }

}
.news-item {

    border-radius: 10px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

    &__image {

        width: 100%;
        height: 181px;
        background: url(/images/image_main.jpg) no-repeat center;
        background-size: cover;
        border-radius: 10px 10px 0 0;

    }

    &-content {

        display: flex;
        flex-direction: column;
        padding: 15px;

    }

    &__title {

        font-size: 16px;
        font-weight: 700;
        color: $color-text;
        margin-bottom: 5px;

    }

    &__date {

        color: $color-gray;
        margin-bottom: 15px;
        font-size: 12px;

    }

    &__text {

        font-size: 15px;
        color: $color-text;
        margin-bottom: 12px;

    }

}
