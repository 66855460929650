@import "../common/vars";

.swiper-container__wrapper {

    width: 100%;
    position: relative;
    margin-bottom: 60px;

}

.swiper-container-news {

    display: flex;
    margin-top: 35px;
    width: 100%;
    overflow: hidden;
    margin-bottom: 60px;

}

.swiper-wrapper-news {

    height: 438px;
    display: flex;

}

.swiper-slide-news {

    background: url('/images/image_main.jpg') no-repeat center;
    background-size: cover;
    height: 100%;
    display: flex;
    align-items: flex-end;
    padding: 36px 72px;
    box-sizing: border-box;


    .swiper-content-news {
    
        background-color: #FFF;
        max-width: 420px;
        width: 100%;
        box-sizing: border-box;
        padding: 32px;
        border-radius: 10px;
        box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.03);

         &__title {
         
            color: $color-text;
            font-size: 16px;
            font-weight: 700;
            margin-bottom: 5px;
         
         }

         &__date {
         
            font-size: 13px;
            color: $color-gray;
            margin-bottom: 15px;
         
         }

         &__text {
            
            font-size: 15px;
            color: $color-text;
            line-height: 1.6;
         
         }

         &__read-more {
         
            font-size: 12px;
            color: #3989f7;
            text-decoration: underline;
         
         }
    
    }

}

.swiper-news-button-next,.swiper-news-button-prev {

    width: 35px;
    height: 35px;
    position: absolute;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 57%;
    cursor: pointer;
    background: $color-green url('/images/arrow_right.svg') no-repeat center;
    background-size: 40%;


}

.swiper-news-button-next {

    right: 20px;

}

.swiper-news-button-prev {

    left: 20px;
    transform: rotateY(180deg);

}

.swiper-pagination {

    position: absolute;
    bottom: 20px;
    display: flex;
    left: 0;
    right: 0;
    justify-content: center;

    .swiper-pagination-bullet {
    
        width: 12px;
        height: 12px;
        border: 3px solid $color-gray;
        border-radius: 50%;
        transition: .6s ease;
        margin-right: 6px;

        &:last-child {
        
            margin-right: 0;
        
        }

        &-active {
        
            border-color: $color-cyan;
            transition: .6s ease;
            
        }
    
    }
    

}



