@import "../common/vars";

.logo {

    display: flex;
    align-items: center;

    &__image {

        background: url('/images/logo.png') no-repeat center;
        background-size: contain;
        width: 157px;
        height: 164px;
        margin-right: 37px;
        flex-shrink: 0;

        @media screen and (max-width: 756px) {
        
            width: 100px;
            margin-right: 2px;
        
        }

    }

    &-text {

        display: flex;
        flex-direction: column;
        max-width: 200px;
        text-align: center;

        &__description {

            color: $color-gray;
            font-size: 12px;
            margin-bottom: 5px;

        }

        &__title {

            color: $color-green;
            font-size: 26px;
            font-family: 'Russo One', sans-serif;

        }

    }

}
