@import "../common/vars";

.pager {

    display: flex;
    margin: 0 auto;
    justify-content: center;

    .page {

        cursor: pointer;
        padding: 17px 22px;
        text-decoration: none;
        margin-right: 20px;
        color: #2f87ff;

        &_active {

            background-color: #2f87ff;
            border-radius: 50px;
            color: #fff;

        }

        &:last-child {

            margin-right: 0;

        }

    }

}
