@import "../common/vars";

.head {

    display: flex;
    flex-direction: column;
    max-width: 263px;
    width: 100%;
    border-image:  linear-gradient(to left, #3389ff, #874ee2 29%, #ff9031 67%, #f4356a) 1;
    border-top: 5px solid;
    margin-right: 35px;

    @media screen and (max-width: 1056px) {

        display: none;
    }

    &__nav-ul {

        display: flex;
        flex-direction: column;
        padding: 0;
    }

    &__nav-li {

        list-style-type: none;
        transition: .6s ease;

        &:hover {
        
            background-color: $color-green;
            transition: .6s ease;
            cursor: pointer;

            .head__nav-span {
            
                color: #fff;
                transition: .6s ease;

            }
        }
    }

    &__nav-span {
    
        font-size: 14px;
        font-weight: 500;
        color: $color-text;
        display: flex;
        padding: 15px 23px;
    }
}
