@import "../common/vars";

.article-page {

    display: flex;
    margin-bottom: 40px;

    @media screen and (max-width: 956px) {

        flex-direction: column;
        padding: 0 10px;

    }

    &__image {

        width: 362px;
        width: 100%;
        height: 256px;
        background: url('/images/image_main.jpg') center no-repeat;
        background-size: cover;
        margin-right: 35px;

        @media screen and (max-width: 956px) {

            margin-bottom: 10px;

        }

    }

    &-content {

        display: flex;
        flex-direction: column;

        &__title {

            color: $color-text;
            font-size: 24px;
            margin-bottom: 10px;

        }

        &__date {
        
            font-size: 14px;
            color: $color-gray;
            margin-bottom: 30px;
        
        }

        &__text {
        
            color: $color-text;
            font-size: 15px;
            margin-bottom: 20px;
        
        }

        &__last-news {
        
            color: #2f87ff;
            font-size: 12px;
        
        }

    }
}
