@import  "../common/vars";

.content {

    display: flex;
    justify-content: space-between;
    width: 100%;

    .main {

        width: 73%;
        display: flex;
        flex-direction: column;

        @media screen and (max-width: 1056px) {
        
            width: 100%;
        
        }
    }

}

.welcome {

    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 1156px) {
    
        display: none;
    
    }

    &-content {
    
        display: flex;
        flex-direction: column;

        &__title {
        
            font-size: 18px;
            font-weight: 700;
            color: #2f87ff;
            margin-bottom: 10px;
        
        }

        &__text {
        
            color: $color-text;
            font-size: 15px;
            line-height: 1.6;
            margin-bottom: 17px;
        
        }
    
    }

    &__image { 
    
        background: url('/images/welcome.jpg') center no-repeat;
        background-size: cover;
        width: 387px;
        height: 370px;
        flex-shrink: 0;
    
    }

}

