@import "../common/vars";

.footer {

    display: flex;
    flex-direction: column;
    padding: 40px 0;

        @media screen and (max-width: 1156px) {
        
            padding: 20px 10px;
        
        }



    .logo {

        margin-right: 20px;
        max-width: 310px;
        width: 100%;

        @media screen and (max-width: 756px) {

            margin-right: 5px;

        }

        @media screen and (max-width: 556px) {

            justify-content: center;

        }

        &__image {

            width: 100px;
            height: 100px;
            margin-right: 10px;

        }

        &-text__description {

            font-size: 11px;

        }
    }

    &-top {

        display: flex;
        margin-bottom: 40px;

    }

    &-bottom {
    
        display: flex;

        @media screen and (max-width: 556px) {
        
            flex-direction: column;
        
        }

        .addresses-item {

            margin-right: 20px;
            max-width: 250px;
            width: 100%;

            @media screen and (max-width: 556px) {

                margin: 0 0 20px 0;

            }


            &:first-child {

                max-width: 310px;
                width: 100%;

            }
            &:last-child {
            
                margin-right: 0;
            
            }

        }
    
    }

}

.menu-footer {

    display: flex;

    @media screen and (max-width: 756px) {
    
        display: none;
    
    }

    &__list {
    
        padding: 0;
        max-width: 250px;
        width: 100%;
        margin-right: 20px;

        &:last-child {
        
            margin-right: 0;
        
        }
    
    }

    &__item {
    
        list-style-type: none;
        margin-bottom: 15px;

        &:last-child {
        
            margin-bottom: 0;
        
        }
    
    }

    &__link {
    
        color: 14px;
        color: $color-text;
        font-weight: 500;
        cursor: pointer;

    }

}

.footer-mobile {

    .menu-footer__list {
    
        max-height: 20px;
        overflow: hidden;
        transition: .6s ease;
        position: relative;
        cursor: pointer;
        margin-bottom: 30px;
        font-size: 18px;
        font-weight: 500;

        &:after {
        
        content: '';
        position: absolute;
        top: 8px;
        right: 180px;
        margin-left: 10px;
        width: 10px;
        height: 5px;
        background: url('/images/togler-down.svg') no-repeat center;
        background-size: contain;
        display: block;
        transition: .6s ease;

        }

        &_active {

            max-height: 1000px;
            transition: .6s ease;

            &:after {
            
                transition: .6s ease;
                transform: rotateX(180deg);

            }

        }
    
    }

    .menu-footer__item {
    
        &:first-child {
        
            margin-top: 10px;
        
        }
    
    }

}
