@import "../common/vars";

.quiz {

    padding: 10px 15px;
    border-radius: 10px;
    background: linear-gradient(213deg, #519aff, #2f87ff);
    display: flex;
    flex-direction: column;
    align-items: center;

    .button {
    
        max-width: none;
    
    }

    &__title {
    
        font-size: 30px;
        font-weight: 700;
        color: #fff;
        font-family: $h2-font;
        margin-bottom: 23px;
    
    }

    &__question {
    
        color: #fff;
        font-style: 15px;
        margin-bottom: 20px;
    
    }

    &-answer {
    
        margin-bottom: 15px;
        display: flex;
        flex-direction: column;
        width: 100%;

        &__row {
        
            display: flex;
            align-items: center;
            margin-bottom: 15px;

            &:last-child {
            
                margin-bottom: 0;
            
            }

        }

        &__text {
        
            color: #fff;
            font-size: 15px;
        
        }

        &__checkbox {
        
            margin-right: 10px;

        }

        &:last-child {
        
            margin-bottom: 0;
        
        }
    
    }

    &__all {
    
        border-bottom: 1px solid #fff;
        padding-bottom: 2px;
        margin-top: 5px;
        color: #fff;
        font-size: 12px;
    
    }

}
