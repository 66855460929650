@import "../common/vars";

.news-page {

    @media screen and (max-width: 1056px) {

        padding: 0 10px;

    }

    &__title {

        font-size: 30px;
        font-weight: 700;
        font-family: $h2-font;
        color: #3989f7;
    
    }

    &-box {
    
        display: flex;
        width: 100%;
        justify-content: space-between;
        flex-wrap: wrap;

        @media screen and (max-width: 756px) {

            justify-content: space-around;

        }

        .news-item {

            margin-bottom: 30px;
            max-width: 263px;
            width: 100%;

            &:last-child {
            
                margin-right: 0;
            
            }
        
        }
    
    }

}
