@import "../common/vars";

.feedback {

    display: flex;
    flex-direction: column;

    @media screen and (max-width: 756px) {
    
        padding: 0 10px;
    
    }

    &__row {
    
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 25px;

        @media screen and (max-width: 756px) {

            justify-content: center;

        }


        &_start {
        
            justify-content: flex-start;
        
        }

        &:last-child {
        
            margin-bottom: 0;
        
        }
    
    }

    &-item {
    
        display: flex;
        flex-direction: column;
        max-width: 270px;
        width: 100%;

        @media screen and (max-width: 756px) {

            max-width: none;

        }

        &__text {

            color: $color-gray;
            font-size: 14px;
            margin-bottom: 10px;

            &_file {

                margin-bottom: 0;
                margin-right: 15px;

                @media screen and (max-width: 756px) {

                    margin-bottom: 10px;

                }

            }

        }

        &__input {

            max-width: 263px;
            width: 100%;
            box-sizing: border-box;
            padding: 15px 5px;
            margin-bottom: 25px;
            font-size: 13px;
            color: $color-gray;

            @media screen and (max-width: 756px) {

                max-width: none;

            }

        }

    }

    &-textarea {
    
        width: 100%;

        &__text {

            color: $color-gray;
            font-size: 14px;
            margin-bottom: 10px;

        }

        &__input {

            width: 100%;
            box-sizing: border-box;
            padding: 20px;
            font-size: 14px;
            color: $color-gray;
            resize: none;
            height: 220px;

        }

    }

}

.capcha {

    width: 100%;
    max-width: 300px;
    height: 80px; 
    margin-right: 10px;
    background-color: $color-gray;

    @media screen and (max-width: 756px) {
    
        margin-right: 0;
    
    }

}

.agree {

    display: flex;
    flex-direction: column;
    margin-right: 10px;

    &__row {
    
        display: flex;
        align-items: center;

        @media screen and (max-width: 756px) {

            margin-bottom: 10px;

            &:first-child {
            
                margin-top: 10px;
            
            }

        }

    }

    &__label {

        font-size: 12px;
        color: $color-text;
    
    }

    &__checkbox {
    
        margin-right: 8px;
        outline: none;
    
    }

}

.comments {

    display: flex;
    flex-direction: column;

    &__item {
    
        display: flex;
        margin-bottom: 30px;

        &_admin {
        
            flex-direction: row-reverse;
        
        }

        &:last-child {
        
            margin-bottom: 0;
        
        }

    }

    &__image {

        width: 65px;
        height: 65px;
        background: $color-green url('/images/person.svg') no-repeat center;
        background-size: 45%;
        margin-right: 35px;
        border-radius: 50px;

        @media screen and (max-width: 756px) {

            width: 35px;
            height: 35px;
            flex-shrink: 0;

        }

        &_admin {
        
            margin-right: 0;
            margin-left: 35px;
            background: $color-cyan url('/images/person.svg') no-repeat center;
            background-size: 45%;

        }

    }

    &-content {
    
        display: flex;
        flex-direction: column;
        padding: 30px;
        border: 1px solid $color-green;
        border-radius: 10px;
        width: 100%;
        max-width: 700px;

        &_admin {
        
            border-color: $color-cyan;
            max-width: 500px;
        
        }

        &__text {
        
            font-size: 15px;
            color: $color-text;
            margin-bottom: 7px;
        
        }

        &__name {
        
            font-size: 14px;
            color: $color-gray;
        
        }

    }


}

