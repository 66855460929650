@import  "../common/vars";

.body {

    width: 100%;
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
    font-family: $default-font;

    &__wrapper {

        max-width: 1200px;
        width: 100%;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;

        @media screen and (max-width: 1056px) {
        
            align-items: center;
        
        }

    }

    a {
    
        text-decoration: none;
    
    }

}

.main-page__title {

    font-family: $h2-font;
    color: $color-text;
    font-size: 30px;

}

.page__title {
    
        font-size: 30px;
        font-weight: 700;
        font-family: $h2-font;
        color: #3989f7;
    
    }
