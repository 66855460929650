@import "../common/vars";

.header {

    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    @media screen and (max-width: 756px) {

        margin-bottom: 60px;

    }

    &-top {
    
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;
        background-color: #f7f7f7;
        padding: 0 10px;

        &__left {
        
            display: flex;
            align-items: center;

        }

        &__right {
        
            display: flex;
            align-items: center;
        
        }

    
    }

    &-main {

        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px;
    
    }

}

.email {

    font-size: 12px;
    color: $color-gray;
    display: flex;
    align-items: center;
    border-right: 1px solid #d3d3d3;
    padding-right: 20px;

    @media screen and (max-width: 726px) {
    
        font-size: 0;
        padding-right: 10px;
    
    }

    &::before {
    
        display: flex;
        content: '';
        width: 11px;
        height: 11px;
        background: url('/images/icon_email.svg') no-repeat center;
        background-size: contain;
        margin-right: 10px;
    
    }

}

.write-us {

    font-size: 12px;
    color: $color-gray;
    display: flex;
    align-items: center;
    padding-left: 20px;

    @media screen and (max-width: 726px) {

        font-size: 0;
        padding-left: 0;

    }

    &:before {

        display: flex;
        content: '';
        width: 11px;
        height: 11px;
        background: url('/images/icon_message.svg') no-repeat center;
        background-size: contain;
        margin-right: 10px;

    
    }

}

.search {

    max-width: 210px;
    width: 100%;
    color: $color-gray;
    font-size: 11px;
    display: flex;
    align-items: center;
    height: 60%;
    padding: 0px 12px;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 50px;

    @media screen and (max-width: 756px) {


    }

    &__input {

        border: none;
        width: 75%;
        height: 100%;
        margin-right: 5px;

    }

    &__button {

        border: none;
        cursor: pointer;
        width: 20px;
        height: 100%;
        background: #fff url('/images/icon_search.svg') no-repeat center;
        background-size: contain;
        padding: 0;
        
        }

}

.accessibility {

    background: $color-cyan url('/images/icon_glasses.svg') no-repeat center;
    background-size: 32%;
    width: 27px;
    height: 18px;
    padding: 14px 32px;
    margin-left: 30px;
    cursor: pointer;

    @media screen and (max-width: 756px) {

        margin-left: 5px;

    }

}

.addresses {

    display: flex;
    margin-left: 130px;

    @media screen and (max-width: 956px) {
    
         margin-left: 30px;
    
    }

    @media screen and (max-width: 796px) {

        display: none;

    }

    &-item {

        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 40px;
        align-items: flex-start;

        &:last-child {

            margin-right: 0;

        }

        &__text {

            color: #2c2c2c;
            font-size: 14px;
            margin-bottom: 18px;
            position: relative;

            &::before {
                
                content: '';
                width: 30px;
                height: 1px;
                background-color: #2f87ff;
                position: absolute;
                bottom: -10px;
                left: 0;
            
            }

        }

        &__phone {

            font-size: 14px;
            color: $color-green-dark; 

        }

    }

}
