@import '../common/vars.scss';

.photogallery {

    @media screen and (max-width: 1056px) {

        padding: 0 10px;

    }

    &-box {

        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        @media screen and (max-width: 756px) {

            justify-content: space-around;

        }

    }

    &-item {

        display: flex;
        flex-direction: column;
        max-width: 263px;
        margin-bottom: 30px;
        width: 100%;

        &__image {

            width: 100%;
            height: 186px;
            margin-bottom: 10px;
            background: url('/images/image_main.jpg') no-repeat center;
            background-size: cover;

        }

        &__text {

            font-size: 15px;
            color: $color-text;
            font-weight: 500;

        }

    }

}
