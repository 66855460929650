@import url('https://fonts.googleapis.com/css?family=Roboto');
@import url('https://fonts.googleapis.com/css?family=Russo+One');
@import url('https://fonts.googleapis.com/css?family=Comfortaa');

$default-font: 'Roboto', sans-serif;
$title-font: 'Russo One', sans-serif;
$h2-font: 'Comfortaa', sans-serif;

$color-gray: #969696;
$color-cyan: #7bbfff;
$color-green: #98d15f;
$color-green-dark: #41a85f;
$color-text: #2c2c2c;
$color-cyan-light: #2f87ff;

$browser-context: 16; // Default

@function em($pixels, $context: $browser-context) {
  @return #{$pixels/$context}em;
}
@function rem($pixels, $context: $browser-context) {
  @return #{$pixels/$context}rem;
}
