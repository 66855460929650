@import "../common/vars";

.menu {

    display: flex;
    justify-content: space-between;
    padding: 0;
    margin: 0;
    margin-bottom: 35px;
    width: 100%;
    padding: 0 10px;
    box-sizing: border-box;

    @media screen and (max-width: 756px) {

        flex-direction: column;
        align-items: center;

    }

    &__list {

        display: flex;

    }

    &__item {

        list-style-type: none;
        border-radius: 5px;
        background: linear-gradient(256deg, #529bff, #2f87ff);
        max-width: 180px;
        width: 100%;
        text-align: center;
        transition: .5s ease; 

        @media screen and (max-width: 756px) {

            margin-bottom: 35px;
            max-width: 250px;


            &:last-child {

                margin-bottom: 0;

            }

        }

        &:hover {

            transition: .5s ease; 
            box-shadow: 0 5px 6px 0 rgba(82, 155, 255, 0.69);

        }

        &:nth-child(2n) {

            background: linear-gradient(256deg, #a56eff, #7046b4);

            &:hover {

                transition: .5s ease; 
                box-shadow: 0 5px 6px 0 rgba(155, 102, 241, 0.69);

            }

        }

        &:nth-child(3n) {

            background: linear-gradient(76deg, #f77506, #f5a940);

            &:hover {

                transition: .5s ease; 
                box-shadow: 0 5px 6px 0 rgba(247, 117, 6, 0.69);

            }

        }

        &:nth-child(4n) {

            background: linear-gradient(256deg, #fd658f, #f43268);

            &:hover {

                transition: .5s ease; 
                box-shadow: 0 5px 6px 0 rgba(253, 101, 143, 0.69);

            }

        }

    }

    &__link {

        color: #fff;
        font-size: 15px;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 16px 0;
        cursor: pointer;

        &::before {

            content: '';
            display: block;
            width: 18.4px;
            height: 15px;
            background: url('/images/icon_parents.svg');
            background-size: contain;
            margin-right: 10px;

        }

    }

    &__title {

        font-size: 16px;
        color: $color-green;
        margin-bottom: 43px;
        display: none;
        text-transform: uppercase;
        position: relative;
        width: 100%;
        text-align: center;

        &:before {

            position: absolute;
            top: -13px;
            left: 0;
            content: '';
            height: 5px;
            width: 100%;
            background: linear-gradient(to left, #3389ff, #874ee2 29%, #ff9031 67%, #f4356a);

        }

        @media screen and (max-width: 756px) {

            display: block;

        }


    }

    &__wrapper {

        display: flex;
        flex-direction: column;
        align-items: stretch;

        @media screen and (max-width: 756px) {

            align-items: center;

        }

    }

}
