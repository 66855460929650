@import "../common/vars";

.contacts {

    @media screen and (max-width: 1056px) {
    
        padding: 0 10px;
    
    }

    &__description {

        font-size: 18px;
        color: $color-text;
        font-weight: 500;

    }

    &-item { 

        display: flex;
        flex-direction: column;
        margin-bottom: 30px;

        &__title {

            color: $color-cyan-light;
            font-weight: 500;
            font-size: 16px;
            margin-bottom: 10px;

        }

        &__text {
        
            font-size: 15px;
            color: $color-text;
        
        }

    }

    &-address {
    
        display: flex;
        width: 100%;
        justify-content: space-between;

        @media screen and (max-width: 1056px) {

            flex-direction: column;

        }

        &-item {
        
            display: flex;
            flex-direction: column;

            &__image {
            
                background: url(/images/contacts__image.jpg) no-repeat center;
                background-size: cover;
                margin-bottom: 10px;
                width: 263px;
                height: 175px;
            
            }

            &__text {

                font-size: 15px;
                color: $color-text;
                margin-bottom: 10px;

            }

            &__phone {
            
                font-size: 15px;
                color: $color-text;
                font-weight: 600;
                margin-bottom: 5px;
            
            }

        }
    
    }

}
