@import "../common/vars";

.button {
    
    display: flex;
    align-items: center;
    justify-content: center; max-width: 180px;
    width: 100%;
    height: 40px;
    color: #fff;
    background-color: $color-green;
    box-shadow: 0 3px 6px 0 rgba(152, 209, 95, 0.71);
    border-radius: 5px 0 5px 0;
    padding: 2px 0;
    text-decoration: none;
    border: none;
    outline: none;

}
